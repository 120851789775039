.radio-label {
  display: flex;
  align-items: baseline;
}
.radio-label input {
  margin: 0 10px 0 0;
}
.radio-label span {
  margin: 0 100% 0 0;
  padding: 0;
}

.dropdown-container {
  width: 100%;
}

.dropdown-content {
  width: 100%;
  display: block;
  margin-left: 0;
  padding-left: 0;
}
