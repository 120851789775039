.valid-input {
  display: flex;
  align-items: center;
}
.refining input {
  max-width: 300px;
}
.validity {
  margin-right: 4px;
}
