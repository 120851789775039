.inventory-item {
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 8px;
  background: #fff;
  max-width: 600px;
}

.inventory-item .item-type {
  margin-top: -10px;
}
