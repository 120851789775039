@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

/* variables */
:root {
  --heading-color: #444;
  --text-color: #999;
  --color-blue: #007be0;
  --color-orange: #ff7f11;
  --color-pink: #e36374;
  --color-green: #50c878;
  --color-blue: #007be0;
  --color-blue: #007be0;
  --primary-color: var(--color-blue);
  --highlight-color: #33a3ff;
  --secondary-color: var(--color-orange);
  --bg-color: #f4f4f4;
  --side-bar-width: 200px;
  --text-default: #111;
  --btn-bg: white;
}

/* base styles */
body {
  font-family: Poppins, sans-serif;
  margin: 0;
  font-size: 1.1em;
  background: var(--bg-color);
}

ul,
li,
p,
h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
}

ul {
  list-style-type: none;
}

/* layout */
.page-title {
  font-size: 1em;
  color: var(--heading-color);
  display: inline-block;
}

.btn {
  background: var(--btn-bg);
  padding: 8px 12px;
  border-radius: 4px;
  color: var(--primary-color);
  cursor: pointer;
  font-size: 1em;
  border: 1px solid var(--primary-color);
}

.secondary {
  color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
}

.btn:hover {
  color: var(--btn-bg);
  background-color: var(--primary-color);
}

.secondary:hover {
  color: var(--btn-bg);
  background-color: var(--secondary-color);
}

.btn:disabled {
  color: #999;
  background-color: #ccc;
  border-color: #999;
  cursor: not-allowed;
}

.secondary:disabled {
  color: #999;
  background-color: #ccc;
  border-color: #999;
  cursor: not-allowed;
}

.BasicList li {
  background: white;
  margin: 15px;
  padding: 10px;
  border-radius: 8px;
  max-width: 600px;
}

/* forms */
label {
  display: block;
  margin: 24px auto;
}

label span {
  display: block;
  margin-bottom: 6px;
}

input,
textarea {
  padding: 8px 6px;
  font-size: 1em;
  color: #777;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 4px;
}

textarea {
  min-height: 160px;
}

.error {
  color: red;
  background: pink;
  border: 1px solid red;
  border-radius: 4px;
  padding: 8px;
  margin: 10px 0;
}

.warning {
  color: goldenrod;
  background: lightgoldenrodyellow;
  border: 1px solid goldenrod;
  border-radius: 4px;
  padding: 8px;
  margin: 10px 0;
}

.basic-tile {
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  max-width: 600px;
}

.custom-mark-link {
  text-decoration: none;
  font-weight: 500;
  color: var(--primary-color);
}
