.side-bar {
  width: var(--side-bar-width);
  min-width: var(--side-bar-width);
  background: var(--primary-color);
  min-height: 100vh;
  box-sizing: border-box;
  position: fixed;
  color: #fff;
}
.side-bar-content {
  position: static;
  width: inherit;
  margin-left: 10px;
  margin-top: 15px;
}
.side-bar .user {
  font-weight: bold;
  text-align: center;
  letter-spacing: 1px;
  padding: 40px 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.side-bar .links {
  margin-top: 40px;
  margin-left: 20px;
}
.side-bar .links li {
  margin-top: 10px;
}
.side-bar .links a {
  display: flex;
  padding: 10px;
  text-decoration: none;
  width: 100%;
  color: #fff;
  box-sizing: border-box;
}
.side-bar .links img {
  margin-right: 10px;
  filter: invert(100%);
}
.side-bar .links a.active {
  color: #555;
  background: var(--bg-color);
  border-radius: 20px 0 0 20px;
}
.side-bar .links .active img {
  filter: invert(40%);
}
.side-bar .btn {
  color: var(--highlight-color);
  border: 1px solid var(--highlight-color);
}

.side-bar .btn:hover {
  color: #fff;
  background-color: var(--highlight-color);
}

.side-bar-content .username {
  color: #fff;
  text-decoration: none;
  font-weight: 500;
}

.side-bar-content .active {
  font-weight: 700;
}
