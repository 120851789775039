.top-bar {
  width: 100%;
  padding: 20px 0;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.top-bar ul {
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: flex-end;
}
.top-bar ul button {
  margin-right: 20px;
}
.top-bar .logo {
  font-weight: bold;
  color: var(--heading-color);
  letter-spacing: -2px;
  font-size: 2em;
  display: flex;
  align-items: center;
  margin-left: 15px;
  margin-right: auto;
}

.top-bar a {
  color: #333;
  text-decoration: none;
  margin-right: 20px;
}

.alpha {
  font-weight: bold;
  font-size: small;
  letter-spacing: 0;
  align-self: baseline;
}
