.task {
  margin-top: 20px;
  background: #fff;
  padding: 15px;
  border-radius: 8px;
  max-width: 600px;
}
.task h2 {
  font-weight: normal;
}
.task .sub-name {
  margin-top: -10px;
  font-size: small;
}
.task .reward {
  word-break: break-all;
}

.tend-bar {
  margin-bottom: 16px;
}
