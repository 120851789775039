.send-message {
  display: flex;
  max-width: 600px;
}

.chat-message {
  background: #fff;
  padding: 15px;
  margin-bottom: 10px;
  max-width: 600px;
  border-radius: 8px;
}

.chat-message .timestamp {
  font-size: small;
  margin-bottom: 15px;
}

.chat-message .uid {
  font-size: small;
  margin-top: -6px;
}

.profile-link {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 500;
}
