.announcement {
  padding: 15px;
  background: #fff;
  max-width: 600px;
  margin-bottom: 20px;
  border-radius: 8px;
}

.create-announcement input {
  max-width: 600px;
}

.create-announcement textarea {
  max-width: 600px;
  resize: vertical;
  max-height: 600px;
}

.announcement-body {
  white-space: pre-wrap;
}
